define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-message", ["exports", "@ember/component", "@ember/object/computed", "discourse-common/utils/decorators", "I18n", "@ember/template-factory"], function (_exports, _component, _computed, _decorators, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="message-block primary">
    {{#if this.showIcon}}
      {{d-icon this.icon}}
    {{/if}}
    <span class="message-content">{{html-safe this.message}}</span>
    {{#if this.hasItems}}
      <ul>
        {{#each this.items as |item|}}
          <li>
            <span>{{d-icon item.icon}}</span>
            <span>{{html-safe item.html}}</span>
          </li>
        {{/each}}
      </ul>
    {{/if}}
  </div>
  
  {{#if this.showDocumentation}}
    <div class="message-block">
      {{d-icon "circle-question"}}
  
      <a href={{this.url}} target="_blank" rel="noopener noreferrer">
        {{this.documentation}}
      </a>
    </div>
  {{/if}}
  */
  {
    "id": "BYwgC55X",
    "block": "[[[10,0],[14,0,\"message-block primary\"],[12],[1,\"\\n\"],[41,[30,0,[\"showIcon\"]],[[[1,\"    \"],[1,[28,[35,2],[[30,0,[\"icon\"]]],null]],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,1],[14,0,\"message-content\"],[12],[1,[28,[35,4],[[30,0,[\"message\"]]],null]],[13],[1,\"\\n\"],[41,[30,0,[\"hasItems\"]],[[[1,\"    \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"items\"]]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[12],[1,\"\\n          \"],[10,1],[12],[1,[28,[35,2],[[30,1,[\"icon\"]]],null]],[13],[1,\"\\n          \"],[10,1],[12],[1,[28,[35,4],[[30,1,[\"html\"]]],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showDocumentation\"]],[[[1,\"  \"],[10,0],[14,0,\"message-block\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"circle-question\"],null]],[1,\"\\n\\n    \"],[10,3],[15,6,[30,0,[\"url\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n      \"],[1,[30,0,[\"documentation\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"item\"],false,[\"div\",\"if\",\"d-icon\",\"span\",\"html-safe\",\"ul\",\"each\",\"-track-array\",\"li\",\"a\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/wizard-message.hbs",
    "isStrictMode": false
  });
  const icons = {
    error: "circle-xmark",
    success: "circle-check",
    warn: "exclamation-circle",
    info: "circle-info"
  };
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    classNameBindings: [":wizard-message", "type", "loading"],
    showDocumentation: (0, _computed.not)("loading"),
    showIcon: (0, _computed.not)("loading"),
    hasItems: (0, _computed.notEmpty)("items"),
    icon(type) {
      return icons[type] || "circle-info";
    },
    message(key, component, opts) {
      return _I18n.default.t(`admin.wizard.message.${component}.${key}`, opts || {});
    },
    documentation(component) {
      return _I18n.default.t(`admin.wizard.message.${component}.documentation`);
    }
  }, [["method", "icon", [(0, _decorators.default)("type")]], ["method", "message", [(0, _decorators.default)("key", "component", "opts")]], ["method", "documentation", [(0, _decorators.default)("component")]]])));
});