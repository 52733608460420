define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-subscription-badge", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    @icon={{this.updateIcon}}
    @action={{this.update}}
    class="btn update"
    @disabled={{this.updating}}
    @title="admin.wizard.subscription.update.title"
  >
    {{#if this.updating}}
      {{loading-spinner size="small"}}
    {{/if}}
  </DButton>
  <DButton
    @action={{this.click}}
    class="wizard-subscription-badge {{this.subscription.subscriptionType}}"
    @title={{this.title}}
  >
    {{d-icon "pavilion-logo"}}
    <span>{{this.label}}</span>
  </DButton>
  */
  {
    "id": "bZPgufNx",
    "block": "[[[8,[39,0],[[24,0,\"btn update\"]],[[\"@icon\",\"@action\",\"@disabled\",\"@title\"],[[30,0,[\"updateIcon\"]],[30,0,[\"update\"]],[30,0,[\"updating\"]],\"admin.wizard.subscription.update.title\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"updating\"]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"size\"],[\"small\"]]]],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"\\n\"],[8,[39,0],[[16,0,[29,[\"wizard-subscription-badge \",[30,0,[\"subscription\",\"subscriptionType\"]]]]]],[[\"@action\",\"@title\"],[[30,0,[\"click\"]],[30,0,[\"title\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,3],[\"pavilion-logo\"],null]],[1,\"\\n  \"],[10,1],[12],[1,[30,0,[\"label\"]]],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"d-button\",\"if\",\"loading-spinner\",\"d-icon\",\"span\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/wizard-subscription-badge.hbs",
    "isStrictMode": false
  });
  class WizardSubscriptionBadge extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "subscription", [_service.service]))();
    #subscription = (() => (dt7948.i(this, "subscription"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "updating", [_tracking.tracked], function () {
      return false;
    }))();
    #updating = (() => (dt7948.i(this, "updating"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "updateIcon", [_tracking.tracked], function () {
      return "arrows-rotate";
    }))();
    #updateIcon = (() => (dt7948.i(this, "updateIcon"), void 0))();
    basePath = "/admin/plugins/subscription-client";
    get i18nKey() {
      return `admin.wizard.subscription.type.${this.subscription.subscriptionType ? this.subscription.subscriptionType : "none"}`;
    }
    static #_4 = (() => dt7948.n(this.prototype, "i18nKey", [(0, _object.computed)("subscription.subscriptionType")]))();
    get title() {
      return `${this.i18nKey}.title`;
    }
    static #_5 = (() => dt7948.n(this.prototype, "title", [(0, _object.computed)("i18nKey")]))();
    get label() {
      return _I18n.default.t(`${this.i18nKey}.label`);
    }
    static #_6 = (() => dt7948.n(this.prototype, "label", [(0, _object.computed)("i18nKey")]))();
    click() {
      window.open(this.subscription.subscriptionCtaLink, "_blank").focus();
    }
    static #_7 = (() => dt7948.n(this.prototype, "click", [_object.action]))();
    update() {
      this.updating = true;
      this.updateIcon = null;
      this.subscription.updateSubscriptionStatus().finally(() => {
        this.updateIcon = "arrows-rotate";
        this.updating = false;
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "update", [_object.action]))();
  }
  _exports.default = WizardSubscriptionBadge;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, WizardSubscriptionBadge);
});